import React, {FunctionComponent, useEffect, useState, useRef} from "react";
import styles from "./SubscriptionPlans.module.css";
import checkIcon from "../../assets/svg/check-icon.svg";
import {apiCall} from "../../api/Api";
import ModalContainer from "../../components/shared/ModalContainer/ModalContainer";
import JobPayment from "../../components/shared/JobPayment/JobPayment";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";
import WarningModal from "../../components/shared/WarningModal/WarningModal";
import {useGlobalContext} from "../../context/GlobalContext/GlobalContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ConfirmJobPaymentModal from "../../components/shared/JobPayment/ConfirmJobPayment";
import ConfirmPaymentModal from "../../components/shared/ConfirmPaymentModal/ConfirmPaymentModal";
import {convertRestApiErrorCodes} from "../../lib/helpers/handleErrors";

const SubscriptionPlans: FunctionComponent = () => {
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const {t} = useTranslation("common");
  const location = useLocation();
  let selectedLanguage = localStorage.getItem("lang");

  const [isLoading, setIsLoading] = useState(false);
  const [chosenPlan, setChosenPlan] = useState("");
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [payWithBankTransfer, setPayWithBankTransfer] = useState(false);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const getPaymentSession = () => {
    if (payWithBankTransfer) {
      setShowBankTransferModal(true);
      return;
    }
    const data = {
      plan: chosenPlan,
    }
    setIsLoading(true);
    apiCall("POST", `subscription/create-checkout-session/`, data)
      .then((res) => {
        const clientSecret = res.data.client_secret;
        setClientSecret(clientSecret); // Set the client secret for the payment
        setShowConfirmPaymentModal(false); // Hide the ConfirmPaymentModal
        setShowPaymentModal(true); // Show the JobPayment component
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const sendBankTransferRequest = () => {
    const data = {
      plan: chosenPlan,
    };
    setIsLoading(true);
    apiCall("POST", `subscription/request-bank-transfer-payment/`, data)
      .then((res) => {
        setIsLoading(false);
        setShowBankTransferModal(false);
        navigate("/plans");
        globalCtx.openMessageModal(
          "warning",
          t("ifYouDontReceiveInvoice"),
        );
        setPayWithBankTransfer(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setPayWithBankTransfer(false);
        globalCtx.openMessageModal(
          "error",
          t("staticMessage.somethingWentWrong"),
        );
        console.log(err);
      });
  }

  useEffect(() => {
    if (chosenPlan) {
      setShowConfirmPaymentModal(true);
    }
  }, [chosenPlan]);

  useEffect(() => {
    // Extract payment_session_id from the URL
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("payment_session_id");

    if (sessionId) {
      // API call to check the session status
      apiCall("GET", `b2b/job-payment/session-status/${sessionId}/`)
        .then((response) => {
          // Check the response status and show modals accordingly
          if (response.data.status === "open") {
            globalCtx.openMessageModal(
              "error",
              t("staticMessage.paymentError"),
            );
          } else if (response.data.status === "complete") {
            globalCtx.openMessageModal(
              "success",
              t("paymentWasSuccessful"),
            );
          }
          // Clean up the URL by removing payment_session_id
          searchParams.delete("payment_session_id");
          navigate(`?${searchParams}`, {replace: true});
        })
        .catch((error) => {
          // Handle errors and show error modal
          const errorMessage = convertRestApiErrorCodes(
            error?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          searchParams.delete("payment_session_id");
          navigate(`?${searchParams}`, {replace: true});
        });
    }
  }, [location.search, navigate, t, globalCtx]);


  const plans = [
    {
      price: "39€",
      priceWithVAT: "46.02€",
      name: "Basic",
      key: "basic",
      features: [
        "30 Ditë",
        "Pa limit",
        "Bazike",
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      backgroundColor: "#2420ff",
    },
    {
      price: "79€",
      priceWithVAT: "93.22€",
      name: "Business+",
      key: "business_plus",
      features: [
        "30 Ditë",
        "Pa limit",
        "Sponsoruar (Në fillim të faqes)",
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
      ],
      backgroundColor: "#3daf19",
    },
    {
      price: "199€",
      priceWithVAT: "234.82",
      name: "Enterprise",
      key: "enterprise",
      features: [
        "30 Ditë",
        "Pa limit",
        "Sponsoruar (Në fillim të faqes)",
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      backgroundColor: "#b200ff",
    },
  ];

  const featureTitles = [
    "Validiteti",
    "Postime pune",
    "Pozita e postimeve",
    "Menaxhimi i kandidatëve nga telefoni",
    "Centralizoni kandidatët nga të gjitha platformat",
    "Komente dhe vlerësime për kandatët",
    "Rrjete Sociale",
    "Krijimi i shabllonëve",
    "Analizat të klikimeve dhe performancës",
    "Peakjobs Search",
    "Shkarkimi i listës së aplikantëve (CSV, Excel)",
    "Ndarje e kandidatëve të preferuar nëpër projekte",
  ];

  return (
    <div className={styles.heroPricing}>
      {showPaymentModal && clientSecret && (
        <ModalContainer position="top">
          <JobPayment
            clientSecret={clientSecret}
            onCloseClick={() => setShowPaymentModal(false)}
          />
        </ModalContainer>
      )}

      {showConfirmPaymentModal && (
        <ConfirmPaymentModal
          buttonLabel={t("jobs.newLongTermJob.completeButton")}
          title={t("pleaseConfirmTheFollowingTransaction")}
          price={
            //   get the plan price based on the chosen plan
            `${plans.find((plan) => plan.key === chosenPlan)?.name}: ${plans.find((plan) => plan.key === chosenPlan)?.price}`
          }
          priceWithVat={
            //   get the plan price with VAT based on the chosen plan
            `${plans.find((plan) => plan.key === chosenPlan)?.name}: ${plans.find((plan) => plan.key === chosenPlan)?.priceWithVAT}`
          }
          onCancel={() => {
            setShowConfirmPaymentModal(false);
            setChosenPlan("")
          }}
          onSubmit={() => {
            getPaymentSession();
            if (payWithBankTransfer) {
              setShowConfirmPaymentModal(false);
            }
          }}
          onSelectedPaymentMethodChange={(selectedPaymentMethod) => {
            setPayWithBankTransfer(selectedPaymentMethod === "payBankTransfer");
          }}
          isLoading={isLoading}
        />
      )}

      {showBankTransferModal && (
        <WarningModal
          title={t("youWillReceiveInvoice")}
          buttonLabel={t("continue")}
          onCancel={() => {
            sendBankTransferRequest();
          }}
          onSubmit={() => {
            sendBankTransferRequest();
          }}
          hideCancelBtn={true}
          isLoading={isLoading}
        />
      )}

      {/* Title Section */}
      <div className={styles.titleSection}>
        <h1 className={styles.title}>Planet e Publikimeve </h1>
        <p className={styles.subtitle}>
          Zgjidhni planin mujor ideal për nevojat e biznesit tuaj.
        </p>
      </div>


      {/* Feature Titles (Visible on Desktop Only) */}
      <div className={styles.featureColumn}>
        {featureTitles.map((title, index) => (
          <div key={index} className={styles.featureTitle}>
            {title}
          </div>
        ))}
      </div>

      {/* Plan Columns */}
      <div className={styles.plans}>
        {plans.map((plan, planIndex) => (
          <div key={planIndex} className={styles.plan} onClick={() => {
            setChosenPlan(plan.key);
          }}>
            <div
              className={styles.planHeader}
              style={{backgroundColor: plan.backgroundColor}}
            >
              <div className={styles.planPrice}>{plan.price}</div>
              <div className={styles.planName}>{plan.name}</div>
            </div>
            {plan.features.map((feature, featureIndex) => (
              <div key={featureIndex} className={styles.planFeature}>
                {window.innerWidth <= 768 ? (
                  // Mobile: Show feature name + value/check
                  <>
                <span className={styles.featureTitleMobile}>
                  {featureTitles[featureIndex]}
                </span>
                    {featureIndex < 3 ? (
                      <span>{feature}</span>
                    ) : feature === true ? (
                      <img className={styles.checkIcon} src={checkIcon} alt="✔"/>
                    ) : (
                      <span>-</span>
                    )}
                  </>
                ) : (
                  // Web: Only show check/value
                  <>
                    {featureIndex < 3 ? (
                      <span>{feature}</span>
                    ) : feature === true ? (
                      <img className={styles.checkIcon} src={checkIcon} alt="✔"/>
                    ) : (
                      <span>-</span>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlans;