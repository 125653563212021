import React from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./WarningModal.scss";
import {useTranslation} from "react-i18next";
import cs from "classnames";

interface WarningModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  title?: string;
  buttonLabel: string;
  hideCancelBtn?: boolean;
  isLoading?: boolean;
}

const WarningModal = (props: WarningModalProps) => {
  const { t } = useTranslation("common");

  return (
    <div className="WarningModal">
      <div className="WarningModal__container">
        <img
          className="WarningModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            props.onCancel();
          }}
        />
        <img src={ErrorIcon} alt="alert icon" className="WarningModal__icon" />
        <h2 className="WarningModal__label">{props.title}</h2>

        <div className="WarningModal__buttons">
          <Button
            type="reset"
            style={{ height: "60px", marginRight: "5px" }}
            className={cs("Button__secondary WarningModal__cancel-btn", props?.hideCancelBtn && "WarningModal__cancel-btn--hide")}
            label={t("warningModal.cancel")}
            onClick={() => {
              props.onCancel();
            }}
          />
          <Button
            type="submit"
            style={{ height: "60px", marginLeft: "5px" }}
            label={props.buttonLabel}
            onClick={() => {
              props.onSubmit();
            }}
            className="WarningModal__submit-btn"
            isLoading={props.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
