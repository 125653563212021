import { createContext, useContext } from "react";
import { User } from "firebase/auth";

export interface AuthContextType {
  currentUser: User | null | undefined;
  localUserProfile: any;
  localOrganization: any;
  setDataToLocalStorage: (userProfile: any, organization: any) => void;
  setOrganizationToLocalStorage: (organization: any) => void;
  signOutFirebaseUser: () => void;
  isOrganizationCreatedMonthAgo: boolean;
  isOrganizationBusinessPlusOrPro: boolean;
  hasSubscription: boolean;
  isFreeTrialOverModalShown: boolean;
  setIsFreeTrialOverModalShown: (value: boolean) => void;
}

const AuthContextValues: AuthContextType = {
  currentUser: null,
  localUserProfile: null,
  localOrganization: null,
  setDataToLocalStorage: () => {},
  setOrganizationToLocalStorage: () => {},
  signOutFirebaseUser: () => {},
  isOrganizationCreatedMonthAgo: false,
  isOrganizationBusinessPlusOrPro: false,
  hasSubscription: false,
  isFreeTrialOverModalShown: false,
  setIsFreeTrialOverModalShown: () => {},
};

export const AuthContext = createContext<AuthContextType>(AuthContextValues);
export const useAuthContext = () => useContext(AuthContext);
