import React, {useState} from "react";
import person from "../../../assets/svg/person.svg";
import clock from "../../../assets/svg/clock.svg";
import {getTimeBetweenDates} from "../../../lib/helpers/getTimeBetweenDates";
import {useNavigate} from "react-router-dom";
import {convertMinsToHrsMins} from "../../../lib/helpers/convertMinsToHrsMins";
import {useTranslation} from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {Countdown} from "../../../components/shared/Countdown/Countdown";

import "./ShortTermJobCard.scss";
import {apiCall} from "../../../api/Api";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import {
  BarChartRounded,
  ExpandCircleDownOutlined,
  FileCopy,
  LowPriority,
  RotateRight,
  Share
} from "@mui/icons-material";
import ConfirmJobPaymentModal from "../../../components/shared/JobPayment/ConfirmJobPayment";
import WarningModal from "../../../components/shared/WarningModal/WarningModal";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {IconButton, ListItemIcon, MenuItem, Popover} from "@mui/material";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../firebaseConfig";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const ShortTermJobCard = (props: any) => {
  const {job, shiftTotal} = props;
  const globalCtx = useGlobalContext();
  const authCtx = useAuthContext();
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const navigate = useNavigate();
  const {t} = useTranslation("common");
  const maxAvailableTime = dayjs(job?.job_start_time).subtract(12, "hour");
  const currentTime = dayjs();
  const diff = maxAvailableTime?.diff(currentTime, "day", true);
  const days = Math.floor(diff);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [showJobPayment, setShowJobPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showChoosePackage, setShowChoosePackage] = useState(false);
  const [isProJob, setIsProJob] = useState(false);
  const [payWithBankTransfer, setPayWithBankTransfer] = useState(false);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#132342',
      },
      secondary: {
        main: '#030ac0',
      }
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: string) => {
    handleClose();
    if (action === "duplicate") {
      localStorage.setItem("duplicateFromShortTermJob", JSON.stringify(job));
      navigate("/jobs/new-short-term-job");
    } else if (action === "promote") {
      promoteJobSession();
    } else if (action === "share") {
      setShowShareLinkModal(true);
    } else if (action === "analytics") {
      navigate("/analytics/job/" + job.id);
    }
    const event = "dropdown_menu_clicked_" + action;
    logEvent(analytics, event, {
        organization: authCtx.localOrganization.name || "unknown",
      }
    );
  };

  const promoteJobSession = () => {
    if (payWithBankTransfer) {
      setShowBankTransferModal(true);
      return;
    }
    setIsLoading(true);
    apiCall("GET", `b2b/job-payment/checkout/${job.id}/?is_pro=true`)
      .then((res) => {
        const clientSecret = res.data.client_secret;
        setClientSecret(clientSecret); // Set the client secret for the payment
        setShowJobPayment(true); // Show the JobPayment component
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPaymentSession = () => {
    if (payWithBankTransfer) {
      setShowBankTransferModal(true);
      return;
    }
    setIsLoading(true);
    apiCall("GET", `b2b/job-payment/checkout/${job.id}/?is_pro=${isProJob}`)
      .then((res) => {
        const clientSecret = res.data.client_secret;
        setClientSecret(clientSecret); // Set the client secret for the payment
        setShowJobPayment(true); // Show the JobPayment component
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <section className="ShortTermJobCard">
      {showJobPayment && clientSecret && (
        <ModalContainer position="top">
          <JobPayment
            clientSecret={clientSecret}
            onCloseClick={() => setShowJobPayment(false)}
          />
        </ModalContainer>
      )}
      {job.is_active ? (
        job.is_promoted ? (
          <div className="ShortTermJobCard__header-pro">
            {t("pro")}
          </div>
        ) : (
          <div className="ShortTermJobCard__header-basic">
            {t("basic")}
          </div>
        )
      ) : (
        <div className="ShortTermJobCard__header-inactive">
          {t("inactive")}
        </div>
      )}

      <div className="ShortTermJobCard__header">
        <Tooltip
          title={`${selectedLanguage === "SQ" ? `Numri i punëtorëve të nevojshëm: ${job.seats}` : `Number of workers needed: ${job.seats}`}`}
          placement="top"
        >
          <h2 className="ShortTermJobCard__job-title">
            {selectedLanguage === "SQ"
              ? `(${job.seats}) ${job.job_title.title?.sq}`
              : `(${job.seats}) ${job.job_title.title?.en}`}
          </h2>
        </Tooltip>
        <div className="ShortTermJobCard__job-price">
          {job.pay_rate}&euro;/h
        </div>
        <div className="ShortTermJobCard__more-vert-container">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ThemeProvider theme={theme}>
              <ExpandCircleDownOutlined color={"secondary"}/>
            </ThemeProvider>
          </IconButton>
          <span className="ShortTermJobCard__new-label">new</span>
        </div>
        <Popover
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: 48 * 4.5,
                width: 'auto',
              },
            },
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick("duplicate")}>
            <ListItemIcon>
              <FileCopy fontSize="small"/>
            </ListItemIcon>
            {t("duplicate")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("share")}>
            <ListItemIcon>
              <Share fontSize="small"/>
            </ListItemIcon>
            {t("share")}
          </MenuItem>
          {/*{job.is_active && !job.is_promoted && (*/}
          {/*  <MenuItem onClick={() => handleMenuItemClick("promote")}>*/}
          {/*    <ListItemIcon>*/}
          {/*      <LowPriority sx={{transform: 'scaleY(-1)'}} fontSize="small"/>*/}
          {/*    </ListItemIcon>*/}
          {/*    {t("promote")}*/}
          {/*  </MenuItem>*/}
          {/*)}*/}
          <MenuItem onClick={() => handleMenuItemClick("analytics")}>
            <ListItemIcon>
              <BarChartRounded fontSize="small"/>
            </ListItemIcon>
            {t("analytics")} &nbsp;  <span className="ShortTermJobCard__new-label">new</span>

          </MenuItem>
        </Popover>
      </div>

      <div className="ShortTermJobCard__body">
        <div className="ShortTermJobCard__city">
          {job.organization_unit?.name}
        </div>

        {!job?.is_active ? (
          (new Date().getTime() - new Date(job.created_at).getTime()) / 1000 / 60 <= 1 ? (
            <div className="LongTermJobCard__processing-text">
              {t("processing")}
              <RotateRight sx={{fontSize: 15}}/>
            </div>
          ) : (
            <div className="LongTermJobCard__pay-button"
                 onClick={() => {
                   setShowChoosePackage(true);
                 }}
            >
              {t("jobs.longTermJobCard.pay")}
            </div>
          )
        ) : job?.status === "available" && job?.job_end_time && job.is_active && (
          <div
            className={cs(
              "ShortTermJobCard__upcoming",
              dayjs().isAfter(dayjs(job.job_end_time)) && "ShortTermJobCard__upcoming--started"
            )}
          >
            {dayjs().isBefore(dayjs(job.job_end_time))
              ? `Skadon ne ${dayjs(job.job_end_time).diff(dayjs(), "day")} dite`
              : `${selectedLanguage === "SQ" ? "Puna ka skaduar" : "Job has expired"}`}
          </div>
        )}
      </div>
      {job.shifts.length > 1 && (
        <div className="ShortTermJobCard__shift-card-body-another-shift"/>
      )}
      <div className="ShortTermJobCard__shift-card-body">
        <div>
          <div className="ShortTermJobCard__shift-card-start">
            {" "}
            {`${daysOfTheWeek[new Date(job.shifts[0]?.start_time).getDay()]} ${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric"
            })}`}
            {new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            }) !==
            new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            })
              ? ` - ${daysOfTheWeek[new Date(job.shifts[0]?.end_time).getDay()]} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
                month: "short",
                day: "numeric"
              })} `
              : ""}
          </div>

          <div className="NewJobForm__shift-card-end">
            {`${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              hour: "numeric",
              minute: "numeric"
            })} ${t("jobs.shortTermJobCard.to")} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
              hour: "numeric",
              minute: "numeric"
            })} `}
          </div>
        </div>
        {shiftTotal && (
          <div className="ShortTermJobCard__shift-card-total">
            {" "}
            {convertMinsToHrsMins(
              getTimeBetweenDates(
                new Date(job.shifts[0]?.start_time),
                new Date(job.shifts[0]?.end_time),
              ).minutes,
            )}{" "}
            total
          </div>
        )}
      </div>
      <div className="ShortTermJobCard__applicants-wrapper">
        <div className="ShortTermJobCard__applicants">
          <img
            src={person}
            alt="person icon"
            className="ShortTermJobCard__person-img"
          />
          {job.status === "assigned" && job.applications_count === 0 && (
            <p> {t("jobs.shortTermJobCard.workers.textOne")}</p>
          )}
          {job.status === "assigned" && job.applications_count !== 0 && (
            <p>
              {job.applications_count}{" "}
              {t("jobs.shortTermJobCard.workers.textTwo")}
            </p>
          )}
          {job.status === "available" && job.applications_count === 0 && (
            <p> {t("jobs.shortTermJobCard.applicants.textOne")}</p>
          )}
          {job.status === "available" && job.applications_count !== 0 && (
            <p className="ShortTermJobCard__applicants-text">
              {job.applications_count}{" "}
              {t("jobs.shortTermJobCard.applicants.textTwo")}
            </p>
          )}
        </div>

        {job.status === "available" && job.applications_count !== 0 && (
          <Tooltip
            title={
              days < 0
                ? `${selectedLanguage === "SQ" ? "Nuk mund të pranoni asnjë kandidat për këtë vend pune" : "You cannot accept any candidates for this job"}`
                : `${selectedLanguage === "SQ" ? "Ju keni edhe kaq kohë për të pranuar kandidatët për këtë punë" : "You still have this much time to accept candidates for this job"}`
            }
            placement="top"
          >
            <div className="ShortTermJobCard__available-job-hours-count">
              <img
                src={clock}
                alt="clock icon"
                className="ShortTermJobCard__clock-icon"
              />
              <span>
                <Countdown endTime={maxAvailableTime}/>
              </span>
            </div>
          </Tooltip>
        )}
      </div>
      {job.status === "available" && (
        <Button
          onClick={() => {
            navigate("/jobs/edit-short-term-job");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          }}
          className="ShortTermJobCard__edit-button"
          label={t("jobs.shortTermJobCard.edit")}
        />
      )}
      <Button
        onClick={() => {
          if (job.status === "available") {
            navigate("/jobs/short-term-applicants");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          } else {
            navigate("/jobs/short-term-attendance");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          }
        }}
        className={cs("ShortTermJobCard__show-btn")}
        label={t(
          job.status === "available"
            ? "jobs.shortTermJobCard.show"
            : "jobs.shortTermJobCard.showWorkers",
        )}
      />
      {showChoosePackage && (
        <ConfirmJobPaymentModal
          buttonLabel={t("jobs.newLongTermJob.completeButton")}
          title={
            selectedLanguage === "SQ"
              ? "Jeni i sigurt që dëshironi ta postoni këtë punë?"
              : "Are you sure you want to post this job?"
          }
          subTitle={
            selectedLanguage === "SQ"
              ? "Deri në pagesën e punës, puna nuk do të jetë e disponueshme për aplikantët."
              : "Until the job payment, the job will not be available for applicants."
          }
          onCancel={() =>
            setShowChoosePackage(false)
          }
          onSubmit={() => {
            getPaymentSession();
            setShowChoosePackage(false)
          }}
          onSelectedPackageChange={(selectedPackage) => {
            setIsProJob(selectedPackage === "pro");
          }}
          onSelectedPaymentMethodChange={(selectedPaymentMethod) => {
            setPayWithBankTransfer(selectedPaymentMethod === "payBankTransfer");
          }}
        />
      )}

      {showBankTransferModal && (
        <WarningModal
          title={t("youWillReceiveInvoiceToActivate")}
          buttonLabel={t("continue")}
          onCancel={() => {
            setShowBankTransferModal(false);
            globalCtx.openMessageModal(
              "warning",
              t("youWillReceiveInvoiceToActivate"),
            );
            setPayWithBankTransfer(false);
          }}
          onSubmit={() => {
            setShowBankTransferModal(false);
            globalCtx.openMessageModal(
              "warning",
              t("youWillReceiveInvoiceToActivate"),
            );
            setPayWithBankTransfer(false);
          }}
          hideCancelBtn={true}
        />
      )}

      {showShareLinkModal && (
        (() => {
          const url = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? "https://apliko.peakjobs.app" : "https://apliko-dev.peakjobs.app";
          const fullUrl = `${url}/job/${job.public_id}`;
          return (
            <WarningModal
              title={t("useThisLinkToGetApplicants") + ":\n" + fullUrl}
              buttonLabel={t("copyLink")}
              onCancel={() => {
                setShowShareLinkModal(false);
              }}
              onSubmit={() => {
                setShowShareLinkModal(false);
                navigator.clipboard.writeText(fullUrl).then(() => {
                  globalCtx.openMessageModal("success", t("linkCopied"));
                  logEvent(analytics, "share_link_copied", {
                    organization: authCtx.localOrganization.name
                  });
                }, () => {
                  globalCtx.openMessageModal("error", t("linkNotCopied"));
                  logEvent(analytics, "share_link_copy_failed", {
                    organization: authCtx.localOrganization.name
                  });
                });
              }}
              hideCancelBtn={true}
            />
          );
        })()
      )}

    </section>
  );
};

export default ShortTermJobCard;
