import React from "react";
import { ReactNode } from "react";
import Dashboard from "../pages/Dashboard/Dashboard";
import SignUp from "../pages/SingUp/SignUp";
import SignIn from "../pages/SignIn/SignIn";
import NotFound from "../pages/NotFound/NotFound";
import Environment from "../pages/Environment/Environment";
import SendEmail from "../pages/SendEmail/SendEmail";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import CreateCompany from "../pages/CreateCompany/CreateCompany";
import AuthPage from "../pages/AuthPage/AuthPage";
import ShortTermNotAssigned from "../pages/Jobs/ShortTermNotAssigned/ShortTermNotAssigned";
import Units from "../pages/Units/Units";
import NewJobForm from "../pages/Jobs/NewJobForm/NewJobForm";
import ShortTermAssigned from "../pages/Jobs/ShortTermAssigned/ShortTermAssigned";
import LongTermNotAssigned from "../pages/Jobs/LongTermNotAssigned/LongTermNotAssigned";
import LongTermAssigned from "../pages/Jobs/LongTermAssigned/LongTermAssigned";
import CompanySettings from "../pages/Company/CompanySettings/CompanySettings";
import EditCompanySettings from "../pages/Company/EditCompanySettings/EditCompanySettings";
import ProfileSettings from "../pages/Profile/ProfileSettings/ProfileSettings";
import EditProfileSettings from "../pages/Profile/EditProfileSettings/EditProfileSettings";
import CreateUnit from "../pages/Units/CreateUnit/CreateUnit";
import EditUnit from "../pages/Units/EditUnit/EditUnit";
import PaymentCards from "../pages/Payments/PaymentCards/PaymentCards";
import Invoices from "../pages/Payments/Invoices/Invoices";
import ShortTermApplications from "../pages/Jobs/Applications/ShortTermApplications/ShortTermApplications";
import ShortTermAttendance from "../pages/Jobs/Attendance/ShortTermAttendance/ShortTermAttendance";
import VerifyPhoneNumber from "../pages/VerifyPhoneNumber/VerifyPhoneNumber";
import ShortTermForm from "../pages/Jobs/ShortTermForm/ShortTermForm";
import LongTermForm from "../pages/Jobs/LongTermForm/LongTermForm";
import Payments from "../pages/Payments/Payments";
import LongTermApplications from "../pages/Jobs/Applications/LongTermApplications/LongTermApplications";
import LongTermAttendance from "../pages/Jobs/Attendance/LongTermAttendance/LongTermAttendance";
import EditLongTermJob from "../pages/Jobs/EditLongTermJob/EditLongTermJob";
import EditShortTermJob from "../pages/Jobs/EditShortTermJob/EditShortTermJob";
import Search from "../pages/Search/Search";
import Lists from "../pages/Lists/Lists";
import ListUsers from "../pages/Lists/ListUsers/ListUsers";
import Plans from "../pages/Plans/Plans";
import JobAnalytics from "../pages/JobAnalytics/JobAnalytics";
import SubscriptionPlans from "../pages/Plans/SubscriptionPlans";

export interface AppRoute {
  path: string;
  name?: string;
  element?: ReactNode;
  childRoute?: { path: string; name?: string; element?: ReactNode }[];
}

export const PrivateRoutesComponents: AppRoute[] = [
  {
    path: "/",
    name: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/units",
    name: "Units",
    element: <Units />,
  },
  {
    path: "/units/new-unit",
    name: "Create New Unit",
    element: <CreateUnit />,
  },
  {
    path: "/units/edit-unit",
    name: "Edit Unit",
    element: <EditUnit />,
  },
  {
    path: "jobs/new-job",
    name: "New Job",
    element: <NewJobForm />,
  },
  {
    path: "jobs/new-short-term-job",
    name: "New Job",
    element: <ShortTermForm />,
  },
  {
    path: "jobs/new-long-term-job",
    name: "New Job",
    element: <LongTermForm />,
  },
  {
    path: "jobs/short-term-not-assigned",
    name: "Short Term Not Assigned",
    element: <ShortTermNotAssigned />,
  },
  {
    path: "jobs/short-term-assigned",
    name: "Short Term Assigned",
    element: <ShortTermAssigned />,
  },
  {
    path: "jobs/long-term-not-assigned",
    name: "Long Term Not Assigned",
    element: <LongTermNotAssigned />,
  },
  {
    path: "jobs/long-term-assigned",
    name: "Completed Jobs",
    element: <LongTermAssigned />,
  },
  {
    path: "jobs/short-term-applicants",
    name: "Short Term Applicants",
    element: <ShortTermApplications />,
  },
  {
    path: "jobs/long-term-applicants",
    name: "Long Term Applicants",
    element: <LongTermApplications />,
  },
  {
    path: "jobs/short-term-attendance",
    name: "Short Term Attendance",
    element: <ShortTermAttendance />,
  },
  {
    path: "jobs/long-term-attendance",
    name: "Long Term Attendance",
    element: <LongTermAttendance />,
  },
  {
    path: "jobs/edit-short-term-job",
    name: "Edit Short Term Job",
    element: <EditShortTermJob />,
  },
  {
    path: "jobs/edit-long-term-job",
    name: "Edit Long Term Job",
    element: <EditLongTermJob />,
  },
  {
    path: "/payments",
    name: "Payments",
    element: <Payments />,
  },
  {
    path: "/payments/payment-cards",
    name: "Payment Cards",
    element: <PaymentCards />,
  },
  {
    path: "/payments/invoices",
    name: "Invoices",
    element: <Invoices />,
  },
  {
    path: "company/company-settings",
    name: "Company Settings",
    element: <CompanySettings />,
  },
  {
    path: "company/edit-company-settings",
    name: "Edit Company Settings",
    element: <EditCompanySettings />,
  },
  {
    path: "/profile/profile-settings",
    name: "Profile Settings",
    element: <ProfileSettings />,
  },
  {
    path: "/profile/edit-profile-settings",
    name: "Edit Profile Settings",
    element: <EditProfileSettings />,
  },
  {
    path: "/search",
    name: "Search",
    element: <Search />,
  },
  {
    path: "/lists",
    name: "Lists",
    element: <Lists />,
  },
  {
    path: "/lists/users/:listId",
    name: "List Users",
    element: <ListUsers />,
  },
  {
    path: "/plans",
    name: "Plans",
    element: <SubscriptionPlans />,
  },
  {
    path: "/analytics/job/:jobId",
    name: "Job Analytics",
    element: <JobAnalytics />,
  }
];

export const PublicRoutesComponents: AppRoute[] = [
  {
    path: "/sign-up",
    name: "Sign Up",
    element: <SignUp />,
  },
  {
    path: "/send-email",
    name: "Send Email",
    element: <SendEmail />,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    element: <SignIn />,
  },
  {
    path: "/auth",
    name: "Auth Page",
    element: <AuthPage />,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    element: <ResetPassword />,
  },
  {
    path: "/create-company",
    name: "Create Organization",
    element: <CreateCompany />,
  },
  {
    path: "/verify-phone-number",
    name: "Verify phone number",
    element: <VerifyPhoneNumber />,
  },

  {
    path: "/environment",
    name: "Environment",
    element: <Environment />,
  },
  {
    path: "/*",
    name: "Not Found",
    element: <NotFound />,
  },
];
