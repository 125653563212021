import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { apiCall } from "../../api/Api";
import { useNavigate } from "react-router-dom";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import CreateCompanyForm from "./CreateCompanyForm/CreateCompanyForm";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import circle from "../../assets/svg/small-circle-opacity-two.svg";
import plusIcon from "../../assets/svg/plus-icon.svg";
import cs from "classnames";

import "./CreateCompany.scss";

const CreateCompany = () => {
  const { t } = useTranslation("common");
  const isMobile = useResponsiveDimensions().isMobile;
  const navigate = useNavigate();

  useEffect(() => {
    //Before creating a company check if phone number is verified
    apiCall("GET", "b2b/user/profile/")
      .then((res) => {
      })
      .catch(() => {});
  }, []);

  return (
    <div className="CreateCompany">
      {!isMobile && (
        <div className="CreateCompany__left">
          <div className="CreateCompany__left-container">
            <div className="CreateCompany__logo">
              <img
                alt="pic"
                src={peakJobsLogo}
                className="CreateCompany__logo-icon"
              />
            </div>
            <div className="CreateCompany__title">
              <h1 className="CreateCompany__title-text">
                {t("createCompany.title.partOne")} <br />{" "}
                {t("createCompany.title.partTwo")}
              </h1>
            </div>
          </div>
          <div className="CreateCompany__circle">
            <img
              alt="pic"
              src={circle}
              className="CreateCompany__circle-background"
            />
            <img
              alt="pic"
              src={plusIcon}
              className="CreateCompany__circle-icon"
            />
          </div>
        </div>
      )}

      <div
        className={cs(
          "CreateCompany__right",
          isMobile && "CreateCompany__right-mobile",
        )}
      >
        <div className="CreateCompany__right-container">
          {isMobile && (
            <div className="CreateCompany__right-head">
              <img
                alt="pic"
                src={peakJobsLogo}
                className="CreateCompany__right-logo"
              />
              <p className="CreateCompany__right-title">
                {t("createCompany.title.partOne")}
                &nbsp;
                {t("createCompany.title.partTwo")}
              </p>
            </div>
          )}

          <div className="CreateCompany__right-body">
            <CreateCompanyForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCompany;
