import React from "react";

import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import calendarIconShort from "../../../assets/svg/calendar-icon2.svg";
import calendarIconLong from "../../../assets/svg/calendar-icon-full.svg";
import plusIcon from "../../../assets/svg/plus-icon-gray.svg";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";

import "./NewJobForm.scss";
import Basic from "../../Plans/Basic/Basic";
import Pro from "../../Plans/Pro/Pro";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import WarningModal from "../../../components/shared/WarningModal/WarningModal";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";

const NewJobForm = () => {
  const {t} = useTranslation("common");
  const authCtx = useAuthContext();
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const [openSubscribeModal, setOpenSubscribeModal] = React.useState(false);

  const navigateToShortTermJob = () => {
    // check if user has a subscription, if not, show a modal. if user has no subscription check if user has a free trial and show a modal if not show a modal to subscribe
    if (authCtx.hasSubscription) {
      console.log("has subscription");
      navigate("/jobs/new-short-term-job");
    } else {
        setOpenSubscribeModal(true);
    }
  }
  const navigateToLongTermJob = () => {
    if (authCtx.hasSubscription) {
      console.log("has subscription");
      navigate("/jobs/new-long-term-job");
    } else {
      setOpenSubscribeModal(true);
    }
  }

  return (
    <>
      {openSubscribeModal && (
        <WarningModal
          title={t("youDontHaveAnyActiveSubscription")}
          buttonLabel={t("subscribe")}
          onCancel={() => {
            setOpenSubscribeModal(false);
          }}
          onSubmit={() => {
            setOpenSubscribeModal(false);
            navigate("/plans");
          }}
          hideCancelBtn={true}
        />
      )}

      <div className="NewJobForm">
        <div className="NewJobForm__header">
          <h1 className="NewJobForm__default-title">{t("jobs.title")}</h1>
          <div className="NewJobForm__header-wrapper">
            <img
              alt="chevron left icon"
              src={chevronLeft}
              className="NewJobForm__back-icon"
              onClick={() => navigate(-1)}
            />
            <h2 className="NewJobForm__title">{t("jobs.newJob.title")}</h2>
          </div>
        </div>
        <div className="NewJobForm__form">
          <div
            className="SchedulePlanButton"
            onClick={() => {
              navigateToShortTermJob();
            }}
          >
            <div className="SchedulePlanButton__header">
              <div className="SchedulePlanButton__header-left">
                <img
                  className="SchedulePlanButton__calendar"
                  alt="pic"
                  src={calendarIconShort}
                />
                <div className="SchedulePlanButton__title">
                  {t("jobs.newJob.newShortTermButton.title")} <br/>
                  <span>{t("jobs.newJob.newShortTermButton.subTitle")}</span>
                </div>
              </div>
              <div className="SchedulePlanButton__header-right">
                <div className="SchedulePlanButton__price">
                </div>
                <img
                  className="SchedulePlanButton__plus"
                  alt="pic"
                  src={plusIcon}
                />
              </div>
            </div>

            <div className="SchedulePlanButton__body">
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newShortTermButton.descriptionOne")}
              </div>
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newShortTermButton.descriptionTwo")}
              </div>
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newShortTermButton.descriptionFifteen")}
              </div>
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newShortTermButton.descriptionSixteen")}
              </div>
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newShortTermButton.descriptionSeventeen")}
              </div>
            </div>
          </div>
          <div
            className="SchedulePlanButton"
            onClick={() => {
              navigateToLongTermJob();
            }}
          >
            <div className="SchedulePlanButton__header">
              <div className="SchedulePlanButton__header-left">
                <img
                  className="SchedulePlanButton__calendar"
                  alt="pic"
                  src={calendarIconLong}
                />
                <div className="SchedulePlanButton__title">
                  {t("jobs.newJob.newLongTermButton.title")} <br/>
                  <span>{t("jobs.newJob.newLongTermButton.subTitle")}</span>
                </div>
              </div>
              <div className="SchedulePlanButton__header-right">
                <div className="SchedulePlanButton__price"></div>
                <img
                  className="SchedulePlanButton__plus"
                  alt="pic"
                  src={plusIcon}
                />
              </div>
            </div>

            <div className="SchedulePlanButton__body">
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newLongTermButton.descriptionOne")}
              </div>
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newLongTermButton.descriptionTwo")}
              </div>
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newLongTermButton.descriptionFour")}
              </div>
              <div className="SchedulePlanButton__paragraph">
                • {t("jobs.newJob.newLongTermButton.descriptionFive")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewJobForm;
