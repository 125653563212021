import React, {useEffect, useState} from "react";
import {useAuthContext} from "../../context/AuthContext/AuthContext";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useResponsiveDimensions} from "../../lib/hooks/useResponsiveDimensions";
import plusIcon from "../../assets/svg/plus-icon.svg";
import dashboardCircle from "../../assets/svg/dashboard-circle.svg";
import "./Dashboard.scss";
import SubscribeForJobsModal from "../../components/shared/SubscribeForJobsModal/SubscribeForJobsModal";
import {apiCall} from "../../api/Api";
import {handleErrors} from "../../lib/helpers/handleErrors";
import {useGlobalContext} from "../../context/GlobalContext/GlobalContext";
import {
  AdsClick,
  AssignmentReturn,
  KeyboardReturn,
  Man,
  Preview,
  TouchApp,
  Visibility,
  Woman
} from "@mui/icons-material";
import {BarChart} from "@mui/x-charts/BarChart";
import Cards from "../JobAnalytics/Cards/Cards";
import SingleCard from "../JobAnalytics/SingleCard/SingleCard";
import Table from "../JobAnalytics/Table/Table";
import TwoCards from "../JobAnalytics/TwoCards/TwoCards";
import info from "../../assets/svg/info.svg";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";

const Dashboard = () => {
  const authCtx = useAuthContext();
  const navigate = useNavigate();
  const {t} = useTranslation("common");
  const isMobile = useResponsiveDimensions().isMobile;
  const [organizationAnalytics, setOrganizationAnalytics] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const globalCtx = useGlobalContext();
  const interactionTimeline = organizationAnalytics.interaction_timeline || {};
  const days = Object.keys(interactionTimeline).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const views = days.map(day => interactionTimeline[day].views);
  const clicks = days.map(day => interactionTimeline[day].clicks);
  const [hasAdvancedPermission, setHasAdvancedPermission] = useState(
    authCtx.isOrganizationBusinessPlusOrPro || !authCtx.isOrganizationCreatedMonthAgo || false
  );

  const getAdvancedOrganizationAnalytics = () => {
    apiCall("GET", `/analytics/b2b/organization/advanced/`).then((res) => {
      setIsLoading(false);
      setOrganizationAnalytics(res.data);
      console.log(res);
    }).catch((error: any) => {
      const errorMessage = handleErrors(error, t);
      globalCtx.openMessageModal("error", errorMessage);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    getAdvancedOrganizationAnalytics();
  }, []);

  return (
    <div className="Dashboard">
      {!hasAdvancedPermission && (
        <div className="WelcomeCart">
          <div className="WelcomeCart__body">
            <p className="WelcomeCart__title">
              <span>{t("dashboard.welcomeCart.title")}</span> {""}
              {isMobile && <br/>}
              <span>{authCtx.localUserProfile.display_name}</span>{" "}
            </p>
            <p className="WelcomeCart__subtitle">
              {t("dashboard.welcomeCart.description")}
            </p>
            <div className="WelcomeCart__steps">
              <div className="WelcomeCart__step">
                <div className="WelcomeCart__step-number">1</div>
                <h5 className="WelcomeCart__step-text">
                  {t("dashboard.welcomeCart.stepOne")}
                </h5>
              </div>
              <div className="WelcomeCart__step">
                <div className="WelcomeCart__step-number">2</div>
                <h5 className="WelcomeCart__step-text">
                  {t("dashboard.welcomeCart.stepTwo")}
                </h5>
              </div>
              <div className="WelcomeCart__step">
                <div className="WelcomeCart__step-number">3</div>
                <h5 className="WelcomeCart__step-text">
                  {t("dashboard.welcomeCart.stepThree")}
                </h5>
              </div>
            </div>
          </div>
          <div className="WelcomeCart__footer">
            <div
              className="WelcomeCart__button"
              onClick={() => {
                navigate("/jobs/short-term-not-assigned");
              }}
            >
              <div className="WelcomeCart__button-round">
                <img
                  alt="pic"
                  src={plusIcon}
                  className="WelcomeCart__button-round--plus"
                />
              </div>
              <p className="WelcomeCart__button-text">
                {t("dashboard.welcomeCart.button")}
              </p>
            </div>
          </div>

          <img alt="pic" src={dashboardCircle} className="WelcomeCart__circle"/>
        </div>
      )}
      {authCtx.isOrganizationCreatedMonthAgo && !authCtx.isFreeTrialOverModalShown && !authCtx.isOrganizationBusinessPlusOrPro && (
        <SubscribeForJobsModal
          onUpgradeClick={() => {
            navigate("/plans");
            authCtx.setIsFreeTrialOverModalShown(true);
          }}
          onCloseClick={() => {
            authCtx.setIsFreeTrialOverModalShown(true);
          }}
        />
      )}
      {organizationAnalytics && hasAdvancedPermission && (
        <div className="WelcomeCart__analytics-container">
          <p className="JobAnalytics__title">
            <span>{t("dashboard.welcomeCart.title")}</span> {""}
            {isMobile && <br/>}
            <span>{authCtx.localUserProfile.display_name} </span>{" "}
          </p>

          <p className="JobAnalytics__subtitle">
            {t("lastDaysInPeakjobs")} {isLoading && (<div className="WelcomeCart__spinner"/>)}
          </p>
          <div className="JobAnalytics__hint-info">
          <img
              src={info}
              alt="info icon"
              className="JobAnalytics__hint-icon"
            />
            <div className="JobAnalytics__hint-text">
              {t("dataAreAvailableFrom")}
            </div>
          </div>
          {!isMobile ? (
            <Cards
              firstCardTitle={t("totalViews")}
              firstCardValue={organizationAnalytics.total_views || '-'}
              firstCardIcon={Visibility}
              secondCardTitle={t("totalClicks")}
              secondCardValue={organizationAnalytics.total_clicks || '-'}
              secondCardIcon={AdsClick}
              thirdCardTitle={t("uniqueViews")}
              thirdCardValue={organizationAnalytics.unique_views || '-'}
              thirdCardIcon={Preview}
              fourthCardTitle={t("uniqueClicks")}
              fourthCardValue={organizationAnalytics.unique_clicks || '-'}
              fourthCardIcon={TouchApp}
              hasAdvancedPermissions={hasAdvancedPermission}
            />
          ) : (
            <div className="JobAnalytics__cards-row-container">
              <SingleCard
                firstCardTitle={t("totalViews")}
                firstCardValue={organizationAnalytics.total_views}
                firstCardIcon={Visibility}
                hasAdvancedPermissions={true}/>
              <SingleCard
                firstCardTitle={t("totalClicks")}
                firstCardValue={organizationAnalytics.total_clicks}
                firstCardIcon={AdsClick}
                hasAdvancedPermissions={true}/>

              <SingleCard
                firstCardTitle={t("uniqueViews")}
                firstCardValue={organizationAnalytics.unique_views}
                firstCardIcon={Preview}
                hasAdvancedPermissions={true}/>

              <SingleCard
                firstCardTitle={t("uniqueClicks")}
                firstCardValue={organizationAnalytics.unique_clicks}
                firstCardIcon={TouchApp}
                hasAdvancedPermissions={true}/>
            </div>
          )}

          {!isMobile ? (

            <div className="JobAnalytics__user-details-container">

              {organizationAnalytics.age_group_distribution && (
                <Table
                  firstRowTitle="16-25"
                  firstRowValue={organizationAnalytics.age_group_distribution["16-25"]}
                  secondRowTitle="26-35"
                  secondRowValue={organizationAnalytics.age_group_distribution["26-35"]}
                  thirdRowTitle="36-45"
                  thirdRowValue={organizationAnalytics.age_group_distribution["36-45"]}
                  fourthRowTitle="46-55"
                  fourthRowValue={organizationAnalytics.age_group_distribution["46-55"]}
                  fifthRowTitle="Other"
                  fifthRowValue={organizationAnalytics.age_group_distribution["Other"]}
                  hasAdvancedPermissions={hasAdvancedPermission}
                />
              )}

              <div className="JobAnalytics__user-details-container-right">

                {organizationAnalytics.gender_distribution && Object.keys(organizationAnalytics.gender_distribution).length > 0 && (
                  <TwoCards
                    firstCardTitle={t("males")}
                    firstCardValue={parseFloat((organizationAnalytics.gender_distribution["M"] || 0).toFixed(2))}
                    secondCardTitle={t("females")}
                    secondCardValue={parseFloat((organizationAnalytics.gender_distribution["F"] || 0).toFixed(2))}
                    firstCardIcon={Man}
                    secondCardIcon={Woman}
                    hasAdvancedPermissions={hasAdvancedPermission}
                  />
                )}

                {organizationAnalytics.engagement_metrics && (
                  <TwoCards
                    firstCardTitle={t("viewToClickRatio")}
                    firstCardValue={organizationAnalytics.engagement_metrics.view_to_click_conversion_rate}
                    secondCardTitle={t("clickToApplyRatio")}
                    secondCardValue={organizationAnalytics.engagement_metrics.click_to_apply_conversion_rate}
                    firstCardIcon={AssignmentReturn}
                    secondCardIcon={KeyboardReturn}
                    hasAdvancedPermissions={hasAdvancedPermission}
                  />
                )}

              </div>
            </div>
          ) : (
            <div className="JobAnalytics__cards-row-container">
              {organizationAnalytics.age_group_distribution && (
                <Table
                  firstRowTitle="16-25"
                  firstRowValue={organizationAnalytics.age_group_distribution["16-25"]}
                  secondRowTitle="26-35"
                  secondRowValue={organizationAnalytics.age_group_distribution["26-35"]}
                  thirdRowTitle="36-45"
                  thirdRowValue={organizationAnalytics.age_group_distribution["36-45"]}
                  fourthRowTitle="46-55"
                  fourthRowValue={organizationAnalytics.age_group_distribution["46-55"]}
                  fifthRowTitle="Other"
                  fifthRowValue={organizationAnalytics.age_group_distribution["Other"]}
                  hasAdvancedPermissions={hasAdvancedPermission}
                />
              )}
              {organizationAnalytics.gender_distribution && Object.keys(organizationAnalytics.gender_distribution).length > 0 && (

              <SingleCard
                firstCardTitle={t("males")}
                firstCardValue={parseFloat((organizationAnalytics.gender_distribution["M"] || 0).toFixed(2))}
                firstCardIcon={Man}
                hasAdvancedPermissions={true}/>
              )}
              {organizationAnalytics.gender_distribution && Object.keys(organizationAnalytics.gender_distribution).length > 0 && (

                <SingleCard
                firstCardTitle={t("females")}
                firstCardValue={parseFloat((organizationAnalytics.gender_distribution["F"] || 0).toFixed(2))}
                firstCardIcon={Woman}
                hasAdvancedPermissions={true}/>
              )}
              {organizationAnalytics.engagement_metrics && (

                <SingleCard
                firstCardTitle={t("viewToClickRatio")}
                firstCardValue={organizationAnalytics.engagement_metrics.view_to_click_conversion_rate}
                firstCardIcon={AssignmentReturn}
                hasAdvancedPermissions={true}/>
                )}
              {organizationAnalytics.engagement_metrics && (

              <SingleCard
                firstCardTitle={t("clickToApplyRatio")}
                firstCardValue={organizationAnalytics.engagement_metrics.click_to_apply_conversion_rate}
                firstCardIcon={KeyboardReturn}
                hasAdvancedPermissions={true}/>
                )}
            </div>
          )}


          {organizationAnalytics.interaction_timeline && (
            <div className="JobAnalytics__chart-container">
              <BarChart
                xAxis={[{
                  scaleType: 'band',
                  data: days.map(day => new Date(day).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short'
                  }))
                }]}
                series={[
                  {data: views, label: t("views")},
                  {data: clicks, label: t("clicks")}
                ]}
                height={300}
                borderRadius={5}
              />
            </div>
          )}
          {organizationAnalytics.interaction_timeline && (
            <p className="JobAnalytics__chart-title">{t("interactionTimeline")}</p>
          )}

        </div>
      )}
    </div>
  );
};

export default Dashboard;
