import React, {useState} from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useTranslation} from "react-i18next";
import cs from "classnames";
import dayjs, {Dayjs} from "dayjs";
import "./DownloadApplicationsModal.scss";
import info from "../../../assets/svg/info.svg";

interface DownloadApplicationsModalProps {
  onCancel: () => void;
  onSubmit: (fromDate: Date | null, toDate: Date | null) => void;
  title?: string;
  buttonLabel: string;
  hideCancelBtn?: boolean;
  isLoading?: boolean;
  fromDate?: Date | null;
  toDate?: Date | null;
}

const DownloadApplicationsModal = (props: DownloadApplicationsModalProps) => {
  const {t} = useTranslation("common");
  const [fromDate, setFromDate] = useState<Date | null>(props.fromDate || null);
  const [toDate, setToDate] = useState<Date | null>(props.toDate || null);

  const handleFromDateChange = (newValue: Dayjs | null) => {
    setFromDate(newValue ? newValue.toDate() : null);
  };

  const handleToDateChange = (newValue: Dayjs | null) => {
    setToDate(newValue ? newValue.toDate() : null);
  };

  return (
    <div className="DownloadApplicationsModal">
      <div className="DownloadApplicationsModal__container">
        <img
          className="DownloadApplicationsModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={props.onCancel}
        />
        <img src={ErrorIcon} alt="alert icon" className="DownloadApplicationsModal__icon"/>
        <h2 className="DownloadApplicationsModal__label">{props.title}</h2>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq">
          <DatePicker
            className="InputField__container"
            value={fromDate ? dayjs(fromDate) : null}
            onChange={handleFromDateChange}
            slotProps={{textField: {placeholder: t("fromDate")}}}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '100%',
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
              '& .MuiInputBase-input': {
                padding: '0 20px',
                height: '71px',
                fontSize: '16px',
                fontFamily: '"Manrope-Bold", sans-serif',
                textAlign: 'left'
              },
            }}
          />
          <DatePicker
            className="InputField__container"
            value={toDate ? dayjs(toDate) : null}
            onChange={handleToDateChange}
            slotProps={{textField: {placeholder: t("toDate")}}}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '100%',
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
              '& .MuiInputBase-input': {
                padding: '0 20px',
                height: '71px',
                fontSize: '16px',
                fontFamily: '"Manrope-Bold", sans-serif',
                textAlign: 'left'
              },
            }}
          />
        </LocalizationProvider>
        <div className="DownloadApplicationsModal__hint-info">
          <img
            src={info}
            alt="info icon"
            className="DownloadApplicationsModal__hint-icon"
          />
          <div className="DownloadApplicationsModal__hint-text">
            {t("invalidDateRange")}
          </div>
        </div>

        <div className="DownloadApplicationsModal__buttons">
          <Button
            type="reset"
            style={{height: "60px", marginRight: "5px"}}
            className={cs("Button__secondary DownloadApplicationsModal__cancel-btn", props?.hideCancelBtn && "DownloadApplicationsModal__cancel-btn--hide")}
            label={t("warningModal.cancel")}
            onClick={props.onCancel}
          />
          <Button
            type="submit"
            style={{height: "60px", marginLeft: "5px"}}
            label={props.buttonLabel}
            onClick={() => props.onSubmit(fromDate, toDate)}
            className="DownloadApplicationsModal__submit-btn"
            isLoading={props.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadApplicationsModal;