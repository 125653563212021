import React, {useEffect, useState} from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./ConfirmPaymentModal.scss";
import {useTranslation} from "react-i18next";
import cs from "classnames";
import {CheckCircleOutline, UnpublishedOutlined} from "@mui/icons-material";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Radio} from "@mui/material";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../firebaseConfig";
import info from "../../../assets/svg/info.svg";

interface ConfirmPaymentModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  onSelectedPaymentMethodChange?: (selectedPaymentMethod: string) => void;
  title?: string;
  price?: string;
  priceWithVat?: string;
  buttonLabel: string;
  hideCancelBtn?: boolean;
  isLoading?: boolean;
}

const ConfirmPaymentModal = (props: ConfirmPaymentModalProps) => {
  const authCtx = useAuthContext();
  logEvent(analytics, "confirm_plan_modal_opened", {
    organization: authCtx.localOrganization.name || "unknown",
  });
  const {t} = useTranslation("common");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("payOnline");


  const handleSelectedPaymentMethodChange = (selectedPaymentMethod: string) => {
    setSelectedPaymentMethod(selectedPaymentMethod);
    if (props.onSelectedPaymentMethodChange) {
      props.onSelectedPaymentMethodChange(selectedPaymentMethod);
    }
  }

  return (
    <div className="ConfirmPaymentModal">
      <div className="ConfirmPaymentModal__container">
        <img
          className="ConfirmPaymentModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            props.onCancel();
          }}
        />
        <img src={ErrorIcon} alt="alert icon" className="ConfirmPaymentModal__icon"/>

        <h2 className="ConfirmPaymentModal__label">
          {props.title}
        </h2>

        <h2 className="ConfirmPaymentModal__price">
          {selectedPaymentMethod === "payBankTransfer" ? props.priceWithVat : props.price}
          {/*{props.price}*/}
        </h2>

        {selectedPaymentMethod === "payBankTransfer" && (
          <div className="ConfirmPaymentModal__hint__wrapper">
            <img
              src={info}
              alt="info icon"
              className="ShortTermForm__info-icon"
            />
            <span className="ConfirmPaymentModal__pay__vat__hint">
                {" "}
              {t("ifYouPayWithBankTransferPayVat")}
              </span>
          </div>
        )}

        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">{t("paymentMethod")}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selectedPaymentMethod}
            name="radio-buttons-group"
          >
            <FormControlLabel onChange={() => handleSelectedPaymentMethodChange("payOnline")} value="payOnline"
                              control={<Radio/>} label={t("payOnlineMethod")}/>
            <FormControlLabel onChange={() => handleSelectedPaymentMethodChange("payBankTransfer")}
                              value="bankTransfer"
                              control={<Radio/>} label={t("payWithBankTransferMethod")}/>
          </RadioGroup>
        </FormControl>

        <div className="ConfirmPaymentModal__buttons">
          <Button
            type="submit"
            style={{height: "60px", marginLeft: "5px"}}
            label={t("continue")}
            onClick={() => {
              props.onSubmit();
            }}
            className="ConfirmPaymentModal__submit-btn"
            isLoading={props.isLoading}
          />
        </div>
      </div>
    </div>

  )
    ;
};

export default ConfirmPaymentModal;
