import React, {useEffect, useState} from "react";
import {AuthContext, AuthContextType} from "./AuthContext";
import {signOut, onAuthStateChanged, User} from "firebase/auth";
import {auth} from "../../firebaseConfig";
import LoadingPage from "../../components/shared/LoadingPage/LoadingPage";

interface AuthContextProps {
  children: React.ReactNode | null;
}

export const AuthContextProvider = (props: AuthContextProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [localUserProfile, setLocalUserProfile] = useState(
    JSON.parse(localStorage.getItem("profile")!!),
  );
  const [localOrganization, setOrganization] = useState(
    JSON.parse(localStorage.getItem("organization")!!),
  );
  const [isFreeTrialOverModalShown, setIsFreeTrialOverModalShownState] = useState(
    JSON.parse(localStorage.getItem("isFreeTrialOverModalShown")!!) || false
  );

  const setIsFreeTrialOverModalShown = (value: boolean) => {
    setIsFreeTrialOverModalShownState(value);
    localStorage.setItem("isFreeTrialOverModalShown", JSON.stringify(value));
  };

  //GET CURRENT USER
  useEffect(() => {
    onAuthStateChanged(auth, () => {
      setCurrentUser(auth.currentUser);
    });
  }, [localUserProfile]);

  //SET USER PROFILE
  const setDataToLocalStorage = (userProfile: any, organization: any) => {
    setLocalUserProfile(userProfile);
    setOrganization(organization);
    localStorage.setItem("profile", JSON.stringify(userProfile));
    localStorage.setItem("organization", JSON.stringify(organization));
  };

  const setOrganizationToLocalStorage = (organization: any) => {
    setOrganization(organization);
    localStorage.setItem("organization", JSON.stringify(organization));
  }
  //SIGN OUT
  const signOutFirebaseUser = () => {
    signOut(auth).then(() => {
      localStorage.removeItem("profile");
      localStorage.removeItem("organization");
      localStorage.removeItem("selectedPlan");
      localStorage.removeItem("isFreeTrialOverModalShown");
    });
  };

  //PUT LOADING PAGE TO STOP RENDERING TWO TIMES THE PAGES BELOW
  if (currentUser === undefined) {
    return <LoadingPage/>;
  }

  const isSubscriptionOver = localOrganization?.subscription_valid_until ? new Date(localOrganization.subscription_valid_until) < new Date() : true;

  const isOrganizationBusinessPlusOrPro = localOrganization?.is_enterprise || localOrganization?.is_business_plus;
  const hasSubscription = !isSubscriptionOver && (localOrganization?.is_enterprise || localOrganization?.is_business_plus || localOrganization?.is_basic);

  //CHECK IF ORGANIZATION IS CREATED MORE THAN A MONTH AGO
  const isOrganizationCreatedMonthAgo = () => {
    if (localOrganization) {
      const organizationCreatedDate = new Date(localOrganization.created_at);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate.getTime() - organizationCreatedDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 30;
    }
    return false;
  };

  const context: AuthContextType = {
    currentUser,
    localUserProfile,
    localOrganization,
    setDataToLocalStorage,
    setOrganizationToLocalStorage,
    signOutFirebaseUser,
    isOrganizationCreatedMonthAgo: isOrganizationCreatedMonthAgo(),
    isOrganizationBusinessPlusOrPro,
    hasSubscription,
    isFreeTrialOverModalShown,
    setIsFreeTrialOverModalShown
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
